// ** Reducers Imports
import { combineReducers } from '@reduxjs/toolkit'
import navbar from './navbar'
import layout from './layout'
import dashboardTodoSlice from 'src/views/dashboard/store/slice'
import TodoListsSlice from 'src/views/dashboard/ecommerce/containers/todo/store/slice'
const rootReducer = combineReducers({
  navbar,
  layout,
  dashboardTodoSlice,
  TodoListsSlice,
})

export default rootReducer
