import axios from 'axios'
import toast from 'react-hot-toast'

axios.defaults.baseURL = process.env.REACT_APP_API
const defaultOptions: any = {
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
}

// Create instance
const instance = axios.create(defaultOptions)
if (localStorage.getItem('auth')) {
  const token: any = localStorage.getItem('auth')
  axios.defaults.headers.common['authorization'] = `Bearer ${token}`
}
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    toast.success(response.data.description)
    return response
  },
  function (error) {
    if (!error.response.request.responseURL.includes('/last/notes')) {
      toast.error(error.response.data.description)
    }
    return Promise.reject(error)
  }
)
