// ** Logo
import logo from '@src/assets/images/logo/e-okul-sistemi-logo-.png'

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner app-loader">
      <div className="loading">
        <div className="effect-1 effects">
          <img className="fallback-logo" src={logo} alt="logo" height={40} />
        </div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
